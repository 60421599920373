
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from '../types';


const  userAuthReducer = (state, action) => {

  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isVerified: true,
        loader: false,
        account: action.payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('cypher', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isVerified: true,
        loader: false,
      };
    case LOGIN_FAIL:
      return {
      ...state,
      loader: false,
      err: action.payload,
    };
    case LOGOUT:
      localStorage.removeItem('cypher');
      return {
        ...state,
        token: null,
        isVerified: false,
        loader: false,
        account: null,
        err: action.payload,
      };
    default:
      return state;
  }
};
export default userAuthReducer