export const userColumns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'firstName',
    headerName: 'Name',
    width: 100,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'imf',
    headerName: 'IMF',
    width: 100,
  },
  {
    field: 'password',
    headerName: 'Password',
    width: 100,
  },
  {
    field: 'cot',
    headerName: 'COT',
    width: 100,
  },
];
export const creditColumns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'firstName',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'balance',
    headerName: 'Balance',
    width: 100,
  },
];

export const transferColumns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'AccNum',
    headerName: 'Account Number',
    width: 150,
  },
  {
    field: 'transactionType',
    headerName: 'Transfer Type',
    width: 100,
  },
  {
    field: 'transactionAcc',
    headerName: 'Transaction Account',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 100,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
  },
];
