import {
  GET_IP,
  IP_ERROR,
  SET_LOADING,
  GET_TXN,
  TXN_ERROR,
  MAKE_TXN,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_IP:
      return {
        ...state,
        ipAdd: action.payload.slice(action.payload.lastIndexOf(':')),
        time: new Date().toDateString(),
        loading: false,
      };
    case GET_TXN:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };
    case MAKE_TXN:
      return {
        ...state,
        transactions: [action.payload, ...state.transactions],
        loading: false,
      };
    case TXN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};
