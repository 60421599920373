import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext'; 
import './AdminHamburger.scss';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(AuthContext); // Using useContext to access context
  const { logoutAdmin } = authContext;
  const navigate = useNavigate()

  const handleLogOut = async(e) => {
    e.preventDefault();
    await logoutAdmin();
    navigate('/admin/login')
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='Adminham'>
      <div
        className="menu-toggle"
        onClick={toggleMenu}
      >
        <div
          className="bar"
          style={{
            transform: isOpen ? 'rotate(-45deg) translate(-6px, 6px)' : 'none'
          }}
        ></div>
        <div
          className="bar"
          style={{
            opacity: isOpen ? '0' : '1'
          }}
        ></div>
        <div
          className="bar"
          style={{
            transform: isOpen ? 'rotate(45deg) translate(-6px, -6px)' : 'none'
          }}
        ></div>
      </div>
      <ul
        className="menu-items"
        style={{ maxHeight: isOpen ? '300px' : '0'}}
      >
        <Link to='/admin/dashboard' style={{ textDecoration: 'none' }} >
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
            
          >
            Home
          </li>
        </Link>
        <Link to='/admin/dashboard/new' style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            New Account
          </li>
        </Link>
        <Link to={'/admin/dashboard'} style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Customers
          </li>
        </Link>
        <Link to={'/admin/dashboard/logs'} style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Orders
          </li>
        </Link>
        <Link to={'/admin/dashboard/credit'} style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Transfers
          </li>
        </Link>
        <Link to='/dashboard' style={{ textDecoration: 'none' }} onClick={handleLogOut}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Logout
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default HamburgerMenu;
