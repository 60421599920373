import React, { useContext, useEffect } from 'react';
import './creditForm.scss';

import InputLabel from '../../components/inputLabel/inputLabel';
import AdminContext from '../../context/admin/adminContext';
import { useState } from 'react';

const CreditForm = ({ creditCard }) => {
  const adminContext = useContext(AdminContext);
  const { current, creditAcc } = adminContext;

  const [formData, setFormData] = useState({
    transactionId: '',
    date: '',
    swift: '',
    AccNum: '',
    transactionAcc: '',
    amount: '',
    status: '',
    name: '',
    transactionType: '',
  });

  useEffect(() => {
    if (current !== null) {
      setFormData({
        transactionId: current[0]._id,
        date: '',
        swift: '',
        AccNum: current[0].accNumber,
        transactionAcc: '',
        amount: '',
        status: '',
        name: current[0].firstName + ' ' + current[0].lastName,
        transactionType: '',
      });
    }
    //eslint-disable-next-line
  }, [current]);

  const {
    transactionId,
    date,
    swift,
    AccNum,
    transactionAcc,
    amount,
    status,
    name,
    transactionType,
  } = formData;

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    creditAcc({ ...formData, [date]: new Date(date) });
    setFormData({
      transactionId: '',
      date: '',
      swift: '',
      AccNum: '',
      transactionAcc: '',
      amount: '',
      status: '',
      name: '',
      transactionType: '',
    });
    creditCard();
  };

  const handleSelectChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.selectedOptions[0].innerText,
    });
  };

  return (
    <div className='left'>
      <p>Process {name} account transactions</p>
      <form onSubmit={handleSubmit}>
        <InputLabel
          labelValue={'Transaction ID'}
          inputType={'text'}
          placeHolder={''}
          inputValue={transactionId}
          inputName={'transactionId'}
          handleInputChange={handleOnChange}
        />
        <InputLabel
          labelValue={'Transaction Date'}
          inputType={'date'}
          placeHolder={''}
          inputValue={date}
          inputName={'date'}
          handleInputChange={handleOnChange}
        />
        <InputLabel
          labelValue={'Swift Number'}
          inputType={'text'}
          placeHolder={''}
          inputValue={swift}
          inputName={'swift'}
          handleInputChange={handleOnChange}
        />
        <InputLabel
          labelValue={'Account Number'}
          inputType={'text'}
          placeHolder={''}
          inputValue={AccNum}
          inputName={'AccNum'}
          handleInputChange={handleOnChange}
        />
        <InputLabel
          labelValue={"Receiver's Bank"}
          inputType={'text'}
          placeHolder={''}
          inputValue={transactionAcc}
          inputName={'transactionAcc'}
          handleInputChange={handleOnChange}
        />
        <InputLabel
          labelValue={'Amount'}
          inputType={'number'}
          placeHolder={''}
          inputValue={amount}
          inputName={'amount'}
          handleInputChange={handleOnChange}
        />
        <div className='formInput'>
          <label htmlFor='status'>Status</label>
          <select
            id='status'
            name='status'
            onChange={handleSelectChange}
            value={status}
          >
            <option>Status</option>
            <option value='Approved'>Approved</option>
            <option value='Pending'>Pending</option>
          </select>
        </div>
        <div className='formInput'>
          <label htmlFor='transactionType'>Transfer Option</label>
          <select
            id='transactionType'
            name='transactionType'
            onChange={handleSelectChange}
            value={transactionType}
          >
            <option>Transfer Option</option>
            <option value='Debit'>Debit</option>
            <option value='Credit'>Credit</option>
          </select>
        </div>
        <button type='submit' onClick={handleSubmit}>
          Fund Account
        </button>
      </form>
    </div>
  );
};

export default CreditForm;
