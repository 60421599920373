import React, { useState } from 'react';
import './creditAccount.scss';
import CreditTable from '../../components/creditTable/CreditTable';
import CreditForm from '../../components/creditForm/CreditForm';

const CreditAccount = () => {
  const [state, setState] = useState(false);

  const creditCard = () => {
    if (state === false) {
      setState(true);
    } else {
      setState(false);
    }
  };
  return (
    <div className='list'>
      <div className='listContainer'>
        {state ? (
          <CreditForm creditCard={creditCard} />
        ) : (
          <CreditTable creditCard={creditCard} />
        )}
      </div>
    </div>
  );
};

export default CreditAccount;
