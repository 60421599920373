import React from 'react';
import './transferLogs.scss';
import TransferTable from '../../components/transferTable/TransferTable';

const TransferLogs = () => {
  return (
    <div className='list'>
      <div className='listContainer'>
        <TransferTable />
      </div>
    </div>
  );
};

export default TransferLogs;
