export const ADMIN_LOADED = 'ADMIN_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS';
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR';
export const SET_DATA = 'SET_DATA';
export const GET_IP = 'GET_IP';
export const IP_ERROR = 'IP_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const CREDIT_ACC = 'CREDIT_ACC';
export const GET_TRANSFERS = 'GET_TRANSFERS';
export const TRANSFER_DATA = 'TRANSFER_DATA';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const GET_TXN = 'GET_TXN';
export const TXN_ERROR = 'TXN_ERROR';
export const MAKE_TXN = 'MAKE_TXN';
