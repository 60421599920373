import './sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';

import StoreIcon from '@mui/icons-material/Store';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import { Link, useNavigate } from 'react-router-dom';
// import { DarkModeContext } from '../../context/darkModeContext';
import { useContext } from 'react';
import UserAuthContext from '../../context/userAuth/userAuthContext';

const Sidebar = () => {
  // const { dispatch } = useContext(DarkModeContext);
  const userAuthContext = useContext(UserAuthContext);
  const { logoutUser } = userAuthContext;
  const navigate = useNavigate()


  const handleLogOut = async() => {
    await logoutUser();
     navigate('/')
  };

  return (
    <div className='clientSidebar'>
      <div className='top'>
        <Link to='/dashboard' style={{ textDecoration: 'none' }}>
          <span className='logo'>Lumina Trust</span>
        </Link>
      </div>
     
      <div className='center'>
        <ul>
          <Link to='/dashboard' style={{ textDecoration: 'none' }}>
          <li>
            <DashboardIcon className='icon' />
            <span>Dashboard</span>
          </li>
          </Link>
         
          
          <Link to='/dashboard/transfer' style={{ textDecoration: 'none' }}>
            <li>
              <StoreIcon className='icon' />
              <span>Transfer</span>
            </li>
          </Link>
          
         
          <Link to={'/dashboard/statistics'}>
            <li>
              <InsertChartIcon className='icon' />
              <span>Stats</span>
            </li>
          </Link>

          
          
          <Link to={'/dashboard/support'}>
            <li>
              <SettingsSystemDaydreamOutlinedIcon className='icon' />
              <span>Support</span>
            </li>
          </Link>
          
          <Link to={'/dashboard/settings'}>
            <li>
              <SettingsApplicationsIcon className='icon' />
              <span>Settings</span>
            </li>
          </Link>
        
         
          <li onClick={handleLogOut}>
            <ExitToAppIcon className='icon' />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      {/* <div className='bottom'>
        <div
          className='colorOption'
          onClick={() => dispatch({ type: 'LIGHT' })}
        ></div>
        <div
          className='colorOption'
          onClick={() => dispatch({ type: 'DARK' })}
        ></div>
      </div> */}
    </div>
  );
};

export default Sidebar;
