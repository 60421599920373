import React, { useReducer } from 'react';
import AdminContext from './adminContext';
import AdminReducer from './adminReducer';
import axios from 'axios';

import {
  ADD_ACCOUNT,
  GET_ACCOUNT,
  DELETE_ACCOUNT,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_ACCOUNT,
  CLEAR_ACCOUNTS,
  SET_DATA,
  ACCOUNT_ERROR,
  SET_LOADING,
  CREDIT_ACC,
  GET_TRANSFERS,
  TRANSFER_DATA,
  UPDATE_TRANSACTION,
} from '../types';
import { devFeature } from '../../utils/dev';
import { toast } from 'react-toastify';

const AdminState = (props) => {
  const initialState = {
    accounts: [],
    transfers: [],
    current: null,
    accountData: [],
    transferData: [],
    error: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(AdminReducer, initialState);

  //Get Account details
  const getAccounts = async () => {
    setLoading();
    try {
      const res = await axios.get( `${devFeature()}/api/admin `);
      dispatch({ type: GET_ACCOUNT, payload: res.data });
      setTable();
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: ACCOUNT_ERROR, payload: error.response.msg });
    }
  };

  //Data table
  const setTable = () => {
    dispatch({ type: SET_DATA });
  };

  //Add an account
  const addAccount = async (account) => {
    setLoading();
    const config = {
      Headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${devFeature()}/api/admin`, account, config);
      dispatch({ type: ADD_ACCOUNT, payload: res.data });
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: ACCOUNT_ERROR, payload: error.response.msg });
    }
  };

  //Delete Account details
  const deleteAccount = async (id) => {
    setLoading();
    try {
      await axios.delete(`${devFeature()}/api/admin/${id}`);
      dispatch({ type: DELETE_ACCOUNT, payload: id });
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: ACCOUNT_ERROR, payload: error.response.msg });
    }
  };

  //Update account details
  const updateAccount = async (account) => {
    setLoading();
    const config = {
      Headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.put(`${devFeature()}/api/admin/${account._id}`, account, config);
      dispatch({ type: UPDATE_ACCOUNT, payload: res.data });
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: ACCOUNT_ERROR, payload: error.response.msg });
    }
  };

  //Clear all accounts
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  //Clear all accounts
  const clearAccounts = () => {
    dispatch({ type: CLEAR_ACCOUNTS });
  };

  //Set current
  const setCurrent = (id) => {
    dispatch({ type: SET_CURRENT, payload: id });
  };

  const creditAcc = async (transact) => {
    setLoading();
    const config = {
      Headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${devFeature()}/api/transfer`, transact, config);
      dispatch({ type: CREDIT_ACC, payload: res.data });
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: ACCOUNT_ERROR, payload: error.response.msg });
    }
  };

  //Get Transfer Details
  const getTransfers = async () => {
    setLoading();
    try {
      const res = await axios.get(`${devFeature()}/api/transfer`);
      dispatch({ type: GET_TRANSFERS, payload: res.data });
      setTransfer();
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: ACCOUNT_ERROR, payload: error.response.msg });
    }
  };

  //update transfer
  const updateTransfer = async (id) => {
    try {
      const res = await axios.put(`${devFeature()}/api/transfer/${id}`);
      dispatch({ type: UPDATE_TRANSACTION, payload: res.data });
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: ACCOUNT_ERROR, payload: error.response.msg });
    }
  };

  //get transfer data
  const setTransfer = () => {
    dispatch({ type: TRANSFER_DATA });
  };
  //set loading
  const setLoading = () => {
    dispatch({ type: SET_LOADING });
  };

  return (
    <AdminContext.Provider
      value={{
        accounts: state.accounts,
        transfers: state.transfers,
        current: state.current,
        error: state.error,
        accountData: state.accountData,
        transferData: state.transferData,
        loading: state.loading,
        getAccounts,
        addAccount,
        deleteAccount,
        updateAccount,
        clearCurrent,
        setCurrent,
        clearAccounts,
        creditAcc,
        getTransfers,
        updateTransfer,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;
