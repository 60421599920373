import React from 'react';
import { useReducer } from 'react';
import UserContext from './userContext';
import UserReducer from './userReducer';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

import {
  GET_IP,
  IP_ERROR,
  SET_LOADING,
  GET_TXN,
  TXN_ERROR,
  MAKE_TXN,
} from '../types';
import { devFeature } from '../../utils/dev';
import { toast } from 'react-toastify';

const UserState = (props) => {
  const initialState = {
    loading: true,
    ipAdd: '',
    time: null,
    transactions: [],
    error: null,
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const getTransfers = async () => {
    setLoading();
    if (localStorage.cypher) {
      setAuthToken(localStorage.cypher);
    }
    try {
      const res = await axios.get(`${devFeature()}/api/usertx`);
      dispatch({ type: GET_TXN, payload: res.data });
      setTransactionTable();
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: TXN_ERROR, payload: error.response.msg });
    }
  };

  //process client's transfer
  const makeTransfer = async (data) => {
    const config = {
      Headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${devFeature()}/api/usertx`, data, config);
      dispatch({ type: MAKE_TXN, payload: res.data });
      toast.info('Transfer initiated')
      window.history.back();
    } catch (error) {
      toast.error(error.response.msg)
      dispatch({ type: TXN_ERROR, payload: error.response.msg });
    }
  };

  //get client's address
  const getIp = async () => {
    try {
      const res = await axios.get(`${devFeature()}/api/address`);
      dispatch({ type: GET_IP, payload: res.data.ip });
    } catch (error) {
      toast.error(error.response.data.msg)
      dispatch({ type: IP_ERROR });
    }
  };

  const setLoading = () => {
    dispatch({ type: SET_LOADING });
  };

  const setTransactionTable = () => {};

  return (
    <UserContext.Provider
      value={{
        ipAdd: state.ipAdd,
        loading: state.loading,
        transactions: state.transactions,
        time: state.time,
        getIp,
        makeTransfer,
        getTransfers,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
