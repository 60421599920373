import React, { useContext, useEffect, useRef } from 'react';
import './creditCard.scss';
import VanillaTilt from 'vanilla-tilt';
import chip from '../../svg/chip.png';
import masterCard from '../../svg/mastercard.svg';
import UserAuthContext from '../../context/userAuth/userAuthContext';
import { useState } from 'react';

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const CreditCard = () => {
  const userAuthContext = useContext(UserAuthContext);
  const { account } = userAuthContext;
  const [state, setState] = useState({
    name: '',
    cardNum: '',
  });

  useEffect(() => {
    if (account !== null) {
      setState({
        name: account.firstName + '  ' + account.lastName,
        cardNum: account.cardNumber,
      });
    }
  }, [account]);

  const { name, cardNum } = state;

  const options = {
    scale: 1.07,
    speed: 1000,
    max: 30,
  };
  return (
    
      <div className='credit-card'>
        <div className='Wrap'>
          <div className='Base'>
          <Tilt className='box' options={options}>
          <div className='Inner-wrap'>
              <div className='bank-details'>
                <div className='bank-logo'>
                  <h2>Lumina Trust</h2>
                  <div className='img'></div>
                </div>
                <div className='logo'></div>
              </div>
              <div className='chip-set'>
                  <img src={chip} alt='' />
              </div>
              <div className='Card-number'>
                <ul>
                  <li>{cardNum.substring(0, 4)}</li>
                  <li>{cardNum.substring(4, 8)}</li>
                  <li>{cardNum.substring(8, 12)}</li>
                  <li>{cardNum.substring(12, 16)}</li>
                </ul>
              </div>
              <div className='Expire'>
                <h4>Good Thru</h4>
                <p>04/27</p>
              </div>
              <div className='card-footer'>
                <div className='Name'>
                  <h3>{name}</h3>
                </div>
                <div className='footer-logo'>
                  <img src={masterCard} alt='' />
                </div>
              </div>
            </div>
    </Tilt>
            
          </div>
        </div>
      </div>
     
  );
};

export default CreditCard;
