import './statistics.scss';
import Featured from '../../components/featured/Featured';
import Chart from '../../components/chart/Chart';
import Table from '../../components/table/Table';
import UserContext from "../../context/user/userContext";
import { useContext, useEffect } from 'react';


const Statistics = () => {

  const userContext = useContext(UserContext);
  const { getTransfers } = userContext;

  useEffect(() => {
      getTransfers();
    //eslint-disable-next-line
  }, []);

  return (
    <div className='statistics_home'>
      <div className='homeContainer_stat'>
        <div className='chartsStatistics'>
          <Featured />
          <Chart title='Last 6 Months (Net expenditure)' aspect={4 / 1} />
        </div>
        <div className='listContainerStatistics'>
          <div className='listTitle'>Latest Transactions</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
