import {
  ADD_ACCOUNT,
  GET_ACCOUNT,
  DELETE_ACCOUNT,
  SET_CURRENT,
  CLEAR_CURRENT,
  UPDATE_ACCOUNT,
  CLEAR_ACCOUNTS,
  ACCOUNT_ERROR,
  SET_DATA,
  SET_LOADING,
  CREDIT_ACC,
  GET_TRANSFERS,
  TRANSFER_DATA,
  UPDATE_TRANSACTION,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_ACCOUNT:
      return {
        ...state,
        accounts: action.payload,
        loading: false,
      };
    case SET_DATA:
      return {
        ...state,
        accountData: state.accounts.map((acc) => {
          return {
            id: acc._id,
            loginID: '',
            firstName: acc.firstName + ' ' + acc.lastName,
            status: acc.status,
            email: acc.email,
            imf: acc.imf,
            password: acc.password,
            cot: acc.cot,
          };
        }),
        loading: false,
      };
    case TRANSFER_DATA:
      return {
        ...state,
        transferData: state.transfers.map((acc) => {
          return {
            id: acc._id,
            name: acc.name,
            AccNum: acc.AccNum,
            transactionType: acc.transactionType,
            transactionAcc: acc.transactionAcc,
            amount: '$' + Number(acc.amount).toLocaleString('en-US'),
            status: acc.status,
          };
        }),
        loading: false,
      };
    case ADD_ACCOUNT:
      return {
        ...state,
        accounts: [action.payload, ...state.accounts],
        loading: false,
      };
    case CREDIT_ACC:
      return {
        ...state,
        transfers: [action.payload, ...state.transfers],
        loading: false,
      };
    case GET_TRANSFERS:
      return {
        ...state,
        transfers: action.payload,
        loading: false,
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map((acc) =>
          acc._id === action.payload._id ? action.payload : acc
        ),
        loading: false,
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.filter((acc) => acc._id !== action.payload),
        loading: false,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: state.accounts.filter((acc) => acc._id === action.payload),
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case CLEAR_ACCOUNTS:
      return {
        ...state,
        accounts: [],
        current: null,
        accountData: [],
        error: null,
      };
    case ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TRANSACTION:
      return {
        ...state,
        transfers: state.transfers.map((txn) =>
          txn._id === action.payload._id ? action.payload : txn
        ),
      };
    default:
      return state;
  }
};
