import React, { useContext, useState, useEffect } from 'react';
import InputLabel from '../../components/inputLabel/inputLabel';
import PasswordInput from '../../components/passwordInput/passwordInput';
import Button from '../../components/button/button';
import './adminLoginPage.scss';
import AuthContext from '../../context/auth/authContext';
import { useNavigate } from 'react-router-dom';

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { loginAdmin, isAuthenticated, error, clearErrors } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin/dashboard');
    }
    if (error === 'Invalid Credentials') {
      // setAlert(error, 'danger');
      clearErrors();
    }
    //eslint-disable-next-line
  }, [error, isAuthenticated]);

  const [contact, setContact] = useState({
    email: '',
    password: '',
  });

  const { email, password } = contact;

  const handleOnChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (email === '' || password === '') {
    } else {
      loginAdmin({
        email,
        password,
      });
    }
  };

  return (
    <div className='UserLogin'>
      <p className='backgroundText'>BANK LOGO</p>
      <div className='UserLoginContainer'>
        <h1 className='pageTitle'>SIGN IN</h1>
        <form className='loginForm' onSubmit={handleOnSubmit}>
          <InputLabel
            labelValue={'Email Address'}
            inputType={'text'}
            inputName={'email'}
            placeHolder={'Enter Email Address'}
            inputValue={email}
            handleInputChange={handleOnChange}
          />
          <PasswordInput
            pwdName={'password'}
            pwdValue={password}
            placeHolder={'Enter password'}
            handleInputChange={handleOnChange}
          />
          <div className='rememberSection'>
            <label className='termsChecked'>
              <input
                type='checkbox'
                // onChange={(e) => setIsEnabled(e.target.checked)}
              />
              <span className='checkmark'></span>
            </label>
            <p className='rememberChecked'>Remember me</p>
          </div>
          <div className='loginButton'>
            <Button
              buttonText={'LOGIN'}
              buttonColor={'var(--tertiary-color)'}
              buttonWidth={'100%'}
              type='submit'
              handleClick={handleOnSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginPage;
