import React from "react"
import propTypes from "prop-types"
import "./inputLabel.scss"

const InputLabel = ({
  labelValue,
  inputValue,
  inputType,
  inputName,
  handleInputChange,
  placeHolder = "",
}) => {
  return (
    <div className="InputLabel" >
      <div className="inputLabelForm" >
        <label className="formLabel">{labelValue}</label>
        <br />
        <input
          className="form_Input"
          type={inputType}
          placeholder={placeHolder}
          value={inputValue}
          name={inputName}
          onChange={(e) => handleInputChange(e)}
        />
      </div>
    </div>
  )
}

InputLabel.propTypes = {
  labelValue: propTypes.string,
  placeHolder: propTypes.string,
  inputValue: propTypes.string,
  labelFor: propTypes.string,
  inputType: propTypes.string,
  inputName: propTypes.string,
  handleInputChange: propTypes.func,
}
export default InputLabel
