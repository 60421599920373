import { DataGrid } from '@mui/x-data-grid';
import { transferColumns } from '../../datatablesource';
import { useContext, useEffect, useState } from 'react';
import './transferTable.scss';
import AdminContext from '../../context/admin/adminContext';
import Loading from '../loading/Loading';
import AuthContext from '../../context/auth/authContext';

const TransferTable = ({ creditCard }) => {
  const adminContext = useContext(AdminContext);
  const { loading, transferData, getTransfers, getAccounts, updateTransfer } =
    adminContext;
  const authContext = useContext(AuthContext);
  const { loadAdmin } = authContext;

  const [data, setData] = useState(transferData);

  useEffect(() => {
    loadAdmin();
    if (loading) {
      getTransfers();
      getAccounts();
    }
    setData(transferData);
    //eslint-disable-next-line
  }, [transferData, loading]);

  const handleChange = (id) => {
    updateTransfer(id);
  };

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        return (
          <div className='cellAction'>
            <div
              className='viewButton'
              onClick={() => handleChange(params.row.id)}
            >
              Change Status
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <div className='datatable'>
          <div className='datatableTitle'>Update status of transaction </div>
          <DataGrid
            className='datagrid'
            rows={data}
            columns={transferColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
          />
        </div>
      )}
    </>
  );
};

export default TransferTable;
