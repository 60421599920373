import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/adminSidebar/Sidebar";
import Navbar from "../components/navbar/AdminNavbar";
import './AdminLayout.scss';


const AdminLayout = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1000);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div>
        <Sidebar style={{display: isMobile ? "none" : "block" }} />
        <Navbar />
        <div className="admin-contain">
        <Outlet />
        </div>
    </div>
  );
};

export default AdminLayout;
