export const userInputs = [
  {
    id: 1,
    label: "Customer's First Name",
    type: 'text',
    placeholder: 'first name',
    value: 'firstName',
  },
  {
    id: 2,
    label: "Customer's Last Name",
    type: 'text',
    placeholder: 'last name',
    value: 'lastName',
  },
  {
    id: 3,
    label: "Customer's Email",
    type: 'mail',
    placeholder: 'user@example.com',
    value: 'email',
  },
  {
    id: 4,
    label: 'Phone',
    type: 'text',
    placeholder: '+1 234 567 89',
    value: 'phone',
  },
  {
    id: 5,
    label: 'Enter State',
    type: 'text',
    placeholder: 'state origin',
  },
  {
    id: 6,
    label: 'Enter city',
    type: 'text',
    placeholder: 'current city',
  },
  {
    id: 7,
    label: 'Account Number',
    type: 'number',
    placeholder: '',
    value: 'accNumber',
  },
  {
    id: 8,
    label: 'Card Number',
    type: 'number',
    placeholder: '',
    value: 'cardNumber',
  },
  {
    id: 9,
    label: 'Login ID',
    type: 'text',
    placeholder: '',
    value: 'loginId',
  },
  {
    id: 10,
    label: 'Login Password',
    type: 'text',
    placeholder: 'Enter password',
    value: 'password',
  },
  {
    id: 11,
    label: 'COT',
    type: 'text',
    placeholder: '',
    value: 'cot',
  },
  {
    id: 12,
    label: 'IMF',
    type: 'text',
    placeholder: '',
    value: 'imf',
  },
  {
    id: 13,
    label: 'Opening Balance',
    type: 'text',
    placeholder: 'account balance',
    value: 'balance',
  },
  {
    id: 14,
    label: 'Error Message if account is set to disabled',
    type: 'text',
    placeholder: 'Error message',
    value: 'errorMessage',
  },
];

export const productInputs = [
  {
    id: 1,
    label: 'Title',
    type: 'text',
    placeholder: 'Apple Macbook Pro',
  },
  {
    id: 2,
    label: 'Description',
    type: 'text',
    placeholder: 'Description',
  },
  {
    id: 3,
    label: 'Category',
    type: 'text',
    placeholder: 'Computers',
  },
  {
    id: 4,
    label: 'Price',
    type: 'text',
    placeholder: '100',
  },
  {
    id: 5,
    label: 'Stock',
    type: 'text',
    placeholder: 'in stock',
  },
];
