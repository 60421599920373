import './table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useContext, useMemo, useState } from 'react';
import UserContext from '../../context/user/userContext';
import Loading from '../loading/Loading';

const List = () => {
  const userContext = useContext(UserContext);
  const { transactions, loading } = userContext;

  const [tableData, setTableData] = useState([]);

  useMemo(() => {
    if (transactions.length > 0) {
      const paginatedData = transactions.slice(0, 10)
      setTableData(paginatedData);
    }
    //eslint-disable-next-line
  }, [transactions]);


  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer component={Paper} className='table'>
        <Table sx={{ minWidth: 650}} aria-label='transaction table' >
          <TableHead>
            <TableRow>
              <TableCell className='tableCell' sx={{whiteSpace: 'nowrap'}}>Transaction ID</TableCell>
              <TableCell className='tableCell' sx={{whiteSpace: 'nowrap'}}>Transaction Date</TableCell>
              <TableCell className='tableCell' sx={{whiteSpace: 'nowrap'}}>Swift</TableCell>
              <TableCell className='tableCell' sx={{whiteSpace: 'nowrap'}}>Account Number</TableCell>
              <TableCell className='tableCell' sx={{whiteSpace: 'nowrap'}}>Receiver's Bank</TableCell>
              <TableCell className='tableCell' sx={{whiteSpace: 'nowrap'}}>Amount</TableCell>
              <TableCell className='tableCell' sx={{whiteSpace: 'nowrap'}}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData && tableData.map((row) => (
              <TableRow key={row._id}>
                <TableCell className='tableCell'>{row._id}</TableCell>
                <TableCell className='tableCell'>
                  <div className='cellWrapper'>
                    {new Date(row.date).toLocaleDateString('en-US')}
                  </div>
                </TableCell>
                <TableCell className='tableCell'>{row.swift !== '' ? row.swift: 'local'}</TableCell>
                <TableCell className='tableCell'>{row.AccNum}</TableCell>
                <TableCell className='tableCell'>
                  {row.transactionAcc !== '' ? row.transactionAcc : 'local'}
                </TableCell>
                <TableCell className='tableCell'>
                  {'$' + Number(row.amount).toLocaleString('en-US')}
                </TableCell>
                <TableCell className='tableCell'>
                  <span className={`status ${row.status}`}>{row.status}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </>
  );
};

export default List;
