import { DataGrid } from '@mui/x-data-grid';
import { userColumns } from '../../datatablesource';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import './datatable.scss';
import AdminContext from '../../context/admin/adminContext';
import AuthContext from '../../context/auth/authContext';
import Loading from '../loading/Loading';

const Datatable = () => {
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);
  const {
    getAccounts,
    getTransfers,
    accountData,
    setCurrent,
    deleteAccount,
    clearCurrent,
    loading,
  } = adminContext;

  const authContext = useContext(AuthContext);
  const { loadAdmin } = authContext;

  const [data, setData] = useState(accountData);

  useEffect(() => {
    loadAdmin();
    if (loading) {
      getAccounts();
      getTransfers();
    }
    setData(accountData);
    //eslint-disable-next-line
  }, [accountData, loading]);

  const handleDelete = (id) => {
    deleteAccount(id);
    clearCurrent();
  };

  const handleEdit = (id) => {
    setCurrent(id);
    navigate('/admin/dashboard/new');
  };

  const handleAdd = () => {
    clearCurrent();
    navigate('/admin/dashboard/new');
  };

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        return (
          <div className='cellAction'>
            <div
              className='viewButton'
              onClick={() => handleEdit(params.row.id)}
            >
              Edit
            </div>
            <div
              className='deleteButton'
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {loading === true ? (
        <Loading />
      ) : (
        <div className='datatable'>
          <div className='datatableTitle'>
            <p className='datatableNewUser'>Add New User</p>
            <div onClick={handleAdd} className='link'>
              Add New
            </div>
          </div>
          <DataGrid
            className='datagrid'
            rows={data}
            columns={userColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
          />
        </div>
      )}
    </>
  );
};

export default Datatable;
