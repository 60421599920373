import React, { useState } from 'react';
import propTypes from 'prop-types';
// import { FaEyeSlash, FaEye } from "react-icons/fa"
import './passwordInput.scss';

const PasswordInput = ({
  pwdValue,
  pwdName,
  handleInputChange,
  placeHolder = '',
  labelValue = 'Password',
}) => {
  const [isShowPwd, setIsShowPwd] = useState(false);

  const togglePassword = () => {
    // inverse the boolean state of isShowPwd
    setIsShowPwd(!isShowPwd);
  };

  return (
    <div className='PasswordInput'>
      <div className='inputLabelForm'>
        <label className='formLabel'>{labelValue}</label> <br />
        <div className='passwordContainer'>
          <input
            className='formInput'
            type={isShowPwd ? 'text' : 'password'}
            placeholder={placeHolder}
            value={pwdValue}
            name={pwdName}
            onChange={(e) => handleInputChange(e)}
          />
          {/* <i className='passwordIcon'>
            {isShowPwd ? (
              <FaEye onClick={togglePassword} />
            ) : (
              <FaEyeSlash onClick={togglePassword} />
            )}
          </i> */}
        </div>
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  // pwdValue: propTypes.string,
  pwdName: propTypes.string,
  placeHolder: propTypes.string,
  handleInputChange: propTypes.func,
};

export default PasswordInput;
