import './list.scss';
import Datatable from '../../components/datatable/Datatable';
import { useContext, useEffect} from 'react';
import AuthContext from '../../context/auth/authContext';

const List = () => {
  const authContext = useContext(AuthContext);
  const { loadAdmin } = authContext;

  useEffect(() => {
    loadAdmin();
    //eslint-disable-next-line
  }, []);

  return (
    <div className='Admminlist'>
      <div className='listContainer'>
        <Datatable />
      </div>
    </div>
  );
};

export default List;
