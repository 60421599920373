import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// ReactDOM.render(
//   <React.StrictMode>
//     <DarkModeContextProvider>
//       <App />
//     </DarkModeContextProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <ToastContainer />
      <App />
    </DarkModeContextProvider>
  </React.StrictMode>
);