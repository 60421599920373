import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserAuthContext from '../../context/userAuth/userAuthContext';

const ProtectedRoute = ({ children }) => {
  const userAuthContext = useContext(UserAuthContext);
  const { isVerified, loader } = userAuthContext;
  return !isVerified && !loader ? <Navigate to='/' /> : children;
};

export default ProtectedRoute;
