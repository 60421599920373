import React, { useContext } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import './alertcard.scss';
import UserAuthContext from '../../context/userAuth/userAuthContext';
import { useNavigate } from 'react-router-dom';

const AlertCard = () => {
  const navigate = useNavigate();
  const userAuthContext = useContext(UserAuthContext);
  const { account } = userAuthContext;
  const { errorMessage } = account;

  const handleClose = () => {
    navigate('/dashboard');
  };
  return (
    <div className='alert-div'>
      <div className='alert-card'>
        <ErrorIcon className='icon' />
        <h3>Error!</h3>
        <p>{errorMessage}</p>
        <button onClick={handleClose}>close</button>
      </div>
    </div>
  );
};

export default AlertCard;
