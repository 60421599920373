import "./support.scss";
import { useState } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Support = ({ title }) => {
  const [loading, setLoading] = useState(false);

  const handleOnChange = () => {};

  const service_id = "service_j2xc9qi"
  const template_id = "template_cyrgdko"
  const public_key = "f7m4scEC-TFsxF8D1"

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        service_id,
        template_id,
        e.target,
        public_key
      )
      .then(
        (result) => {
          toast.success("Message sent successfully!");
          e.target.reset(); // Clear form inputs
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
           toast.error('Falied try again later')
        }
      );
  };


  return (
    <div className="new_Support">
      <div className="newContainer_support">
        <div className="top_support">
          <h1 className="supportTitle">{title}</h1>
        </div>
        <div className="bottom_support">
          <div className="left_support">
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <label htmlFor="message">Name</label>
              <input
                labelValue={"Name"}
                inputType={"text"}
                placeHolder={""}
                // inputValue={''}
                inputName={"account"}
                handleInputChange={handleOnChange}
                name={"name"}
                style={{ padding: "1rem" }}
                required
              />
              <label htmlFor="message">Email</label>
              <input
                labelValue={"Email"}
                inputType={"email"}
                placeHolder={""}
                // inputValue={''}
                inputName={"amount"}
                handleInputChange={handleOnChange}
                name={"email"}
                style={{ padding: "1rem" }}
                required
              />
              <label htmlFor="message">Phone</label>
              <input
                labelValue={"Phone"}
                inputType={"number"}
                placeHolder={""}
                // inputValue={''}
                inputName={"amount"}
                handleInputChange={handleOnChange}
                name={"number"}
                style={{ padding: "1rem" }}
                required
              />
              <label htmlFor="message">Message</label>
              <div className="message-box">
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  style={{ padding: "1rem" }}
                  required
                ></textarea>
              </div>
              <button type="submit" disabled={loading}>
                {loading ? "Sending..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
