import React from 'react';
import './loading.scss';

const Loading = () => {
  return (
    <div className='loader-div'>
      <div class='loadingio-spinner-rolling-57eevzpp4c'>
        <div class='ldio-rrwx22cufba'>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
