import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
// import AuthContext from '../../context/auth/authContext'; 
import './HamburgerMenu.scss';
import userAuthContext from '../../context/userAuth/userAuthContext';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(userAuthContext); // Using useContext to access context
  const { logoutUser } = authContext;

  const handleLogOut = (e) => {
    e.preventDefault();
    logoutUser();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='ham'>
      <div
        className="menu-toggle"
        onClick={toggleMenu}
      >
        <div
          className="bar"
          style={{
            transform: isOpen ? 'rotate(-45deg) translate(-6px, 6px)' : 'none'
          }}
        ></div>
        <div
          className="bar"
          style={{
            opacity: isOpen ? '0' : '1'
          }}
        ></div>
        <div
          className="bar"
          style={{
            transform: isOpen ? 'rotate(45deg) translate(-6px, -6px)' : 'none'
          }}
        ></div>
      </div>
      <ul
        className="menu-items"
        style={{ maxHeight: isOpen ? '300px' : '0'}}
      >
        <Link to='/dashboard' style={{ textDecoration: 'none' }} >
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
            
          >
            Home
          </li>
        </Link>
        <Link to='/dashboard/transfer' style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Transfer
          </li>
        </Link>
        <Link to={'/dashboard/statistics'} style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Statistics
          </li>
        </Link>
        <Link to={'/dashboard/support'} style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Support
          </li>
        </Link>
        <Link to={'/dashboard/settings'} style={{ textDecoration: 'none' }}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Settings
          </li>
        </Link>
        <Link to='/dashboard' style={{ textDecoration: 'none' }} onClick={handleLogOut}>
          <li
            className="menu-item"
            style={{
              padding: '15px',
              borderBottom: '1px solid #ddd',
              transition: 'background-color 0.3s ease',
              color: 'black'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#e0e0e0'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'inherit'}
          >
            Logout
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default HamburgerMenu;
