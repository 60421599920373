import Home from "./pages/home/Home";
import List from "./pages/list/List";
import CreditAccount from "./pages/creditAccount/CreditAccount";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { productInputs } from "./formSource";
import "./style/dark.scss";
import "./app.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import UserLoginPage from "./pages/userLoginPage/userLoginPage";
import AdminLoginPage from "./pages/adminLoginPage/AdminLoginPage";
import AuthState from "./context/auth/AuthState";
import PrivateRoute from "./components/routing/PrivateRoute";
import AdminState from "./context/admin/AdminState";
import setAuthToken from "./utils/setAuthToken";
import Transfer from "./pages/transfer/Transfer";
import UserState from "./context/user/UserState";
import Statistics from "./pages/statistics/Statistics";
import Settings from "./pages/settings/Settings";
import Support from "./pages/support/Support";
import UserAuthState from "./context/userAuth/UserAuthState";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import TransferLogs from "./pages/transferLogs/TransferLogs";
import Layout from "./layout/Layout";
import AdminLayout from "./layout/AdminLayout";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <AuthState>
      <AdminState>
        <UserAuthState>
          <UserState>
            <div className={darkMode ? "app dark" : "app"}>
              <BrowserRouter>
                <Routes>
                  <Route index element={<UserLoginPage />} />
                  <Route path="dashboard" element={<Layout />}>
                    <Route
                      index
                      element={
                        <ProtectedRoute>
                          <Home />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="transfer"
                      element={
                        <ProtectedRoute>
                          <Transfer title={"Transfer"} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="statistics"
                      element={
                        <ProtectedRoute>
                          <Statistics />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="settings"
                      element={
                        <ProtectedRoute>
                          <Settings title={"Update Password"} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="support"
                      element={
                        <ProtectedRoute>
                          <Support title={"Contact Support"} />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route path="/admin/login" element={<AdminLoginPage />} />
                  <Route path="admin" element={<AdminLayout />}>
                    <Route path="dashboard">
                      <Route
                        index
                        element={
                          <PrivateRoute>
                            <List />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="credit"
                        element={
                          <PrivateRoute>
                            <CreditAccount />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="logs"
                        element={
                          <PrivateRoute>
                            <TransferLogs />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="new"
                        element={
                          <PrivateRoute>
                            <New
                              inputs={productInputs}
                              title="Add New Account"
                            />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                  </Route>
                </Routes>
              </BrowserRouter>
            </div>
          </UserState>
        </UserAuthState>
      </AdminState>
    </AuthState>
  );
}

export default App;
