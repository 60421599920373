import React from "react"
import propTypes from "prop-types"
import "./button.scss"

const Button = ({
  buttonText,
  buttonIcon,
  buttonColor,
  buttonWidth,
  type = "button",
  isEnabled = true,
  handleClick,
}) => {
  return (
    <div className="Button">
      <div className="buttonContainer">
        <button
          type={type}
          disabled={!isEnabled}
          className="button smallButton"
          style={{
            backgroundColor: isEnabled === false ? "gray" : buttonColor,
            width: buttonWidth,
            cursor: isEnabled === false ? "not-allowed" : "pointer",
          }}
          onClick={(e) => handleClick(e)}
        >
          {buttonText ? buttonText : ""}
          <i>{buttonIcon ? buttonIcon : ""}</i>
        </button>
      </div>
    </div>
  )
}
Button.propTypes = {
  buttonText: propTypes.string,
  buttonIcon: propTypes.element,
  buttonColor: propTypes.string,
  handleClick: propTypes.func,
  buttonWidth: propTypes.string,
}
export default Button
