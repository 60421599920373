import React, { useReducer } from 'react';
import UserAuthReducer from './userAuthReducer';
import UserAuthContext from './userAuthContext';
import axios from 'axios';
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from '../types';
import setAuthToken from '../../utils/setAuthToken';
import { devFeature } from '../../utils/dev';
import { toast } from 'react-toastify';


const UserAuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('cypher'),
    isVerified: null,
    loader: true,
    account: null,
    err: null,
  };

  const [state, dispatch] = useReducer(UserAuthReducer, initialState);

  const loadUser = async () => {
    if (localStorage.cypher) {
      setAuthToken(localStorage.cypher);
    }

    try {
      const res = await axios.get(`${devFeature()}/api/auth`);
     
      dispatch({
        type: USER_LOADED,
       
        payload: res.data,
      });
    } catch (error) {
      // toast.error(error.response.data.msg)
      dispatch({ type: AUTH_ERROR });
    }
  };

  //login user
  const loginUser = async (loginData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(`${devFeature()}/api/auth`, loginData, config);
      state.loader = true
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      loadUser();
      state.loader = true
    } catch (err) {
      toast.error(err.response.data.msg)
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  const logoutUser = () => {
    dispatch({ type: LOGOUT });
  };

  //Clear Errors
  const clearErr = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <UserAuthContext.Provider
      value={{
        token: state.token,
        isVerified: state.isVerified,
        loader: state.loader,
        account: state.account,
        err: state.err,
        loadUser,
        loginUser,
        logoutUser,
        clearErr,
      }}
    >
      {props.children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthState;
