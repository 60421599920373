import './settings.scss';
import InputLabel from '../../components/inputLabel/inputLabel';
import { useContext } from 'react';
import UserContext from '../../context/user/userContext';

const Settings = ({ title }) => {
  const userContext = useContext(UserContext);

  const handleOnChange = () => {};

  const handleSubmit = () => {};

  return (
    <div className='settings_style'>
      <div className='newContainer_settings'>
        <div className='top_settings'>
          <h1>{title}</h1>
        </div>
        <div className='bottom_settings'>
          <div className='left_settings' style={{width: '100%'}}>
            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', width: '100%'}}> 
              <InputLabel
                labelValue={'Enter Current Password'}
                inputType={'text'}
                placeHolder={''}
                // inputValue={''}
                inputName={'account'}
                handleInputChange={handleOnChange}
              />
              <InputLabel
                labelValue={'Enter New Password'}
                inputType={'text'}
                placeHolder={''}
                // inputValue={''}
                inputName={'amount'}
                handleInputChange={handleOnChange}
              />
              <InputLabel
                labelValue={'Confirm New Password'}
                inputType={'text'}
                placeHolder={''}
                // inputValue={''}
                inputName={'amount'}
                handleInputChange={handleOnChange}
              />
              <button className='settings_button' type='submit' onClick={handleSubmit}>
                Update Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
