import "./widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useContext, useEffect } from "react";
import UserAuthContext from "../../context/userAuth/userAuthContext";
import { useState } from "react";

const Widget = ({ type }) => {
  const userAuthContext = useContext(UserAuthContext);
  const { account, loadUser } = userAuthContext;
  const [cash, setCash] = useState("");

  useEffect(() => {
   
    if (account === null) {
      loadUser();
    } else {
      setCash(account.balance);
    }
    //eslint-disable-next-line
  }, [account]);

  let data;

  //temporary
  const amount = Number(cash).toLocaleString("en-US");
  

  switch (type) {
    case "withdraw":
      data = {
        title: "WITHDRAW",
        isMoney: false,
        link: "",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "transfer":
      data = {
        title: "TRANSFER",
        isMoney: false,
        link: "Recent transactions",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "ACCOUNT BALANCE",
        isMoney: true,
        link: "Net earnings",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "deposit":
      data = {
        title: "DEPOSIT",
        isMoney: true,
        link: "",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="my_widget" >
      <div
        className="left_widget"
       
      >
        <span className="title">{data.title}</span>
        <span className="counter" style={{ fontSize: "1.5rem" }}>
          {data.isMoney && "$"}{cash !== '' ? amount : '0'}
        </span>
        <span className="link" style={{ marginTop: "0.5rem" }}>
          {data.link}
        </span>
      </div>
      <div className="right_widget">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
