import './transfer.scss';

import InputLabel from '../../components/inputLabel/inputLabel';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/user/userContext';
import AlertCard from '../../components/alertcard/AlertCard';
import UserAuthContext from '../../context/userAuth/userAuthContext';

const Transfer = ({ title }) => {
  const [transfer, setTransfer] = useState(false);
  const userContext = useContext(UserContext);
  const userAuthContext = useContext(UserAuthContext);
  const { account, loadUser } = userAuthContext;
  const { ipAdd, time, makeTransfer } = userContext;


  const [accData, setAccData] = useState({
    transactionId: '',
    date: '',
    swift: '',
    AccNum: '',
    transactionAcc: '',
    amount: '',
    status: '',
    name: '',
    email: '',
    transactionType: '',
  });

  useEffect(() => {
    if (account === null) {
      loadUser();
    } else {
      setAccData({
        transactionId: account._id,
        date: Date.now(),
        swift: '',
        AccNum: account.accNumber,
        transactionAcc: '',
        amount: '',
        status: 'Pending',
        name: account.firstName + ' ' + account.lastName,
        transactionType: 'Debit',
        email: account.email,
      });
    }
   
    //eslint-disable-next-line
  }, [account]);


  const { swift, AccNum, transactionAcc, amount } = accData;
  // console.log(account);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setTransfer(true);
    } else {
      setTransfer(false);
      console.log('fail');
    }
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setAccData({ ...accData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    makeTransfer(accData);
    setAccData({
      transactionId: account._id,
      date: Date.now(),
      swift: '',
      AccNum: account.accNumber,
      transactionAcc: '',
      amount: '',
      status: 'Pending',
      name: account.firstName + ' ' + account.lastName,
      transactionType: 'Debit',
      email: account.email,
    });
  };

  if (account?.status && account.status === 'In-active') {
    return (
      <div className='new'>
        <div className='newContainer'>
          <div className='left'>
            <AlertCard />;
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='newClient'>
      <div className='newContainer'>
        <div>
          <div className='topTansfer alert'>
            <h1>{title}</h1>
            <div className='info-card'>
              <div className='ip-card'>
                <h3 style={{fontSize: 'clamp(1.5rem, 2vw, 1.8rem)', textTransform: 'capitalize'}}>Dear, {account && account.firstName + ' ' + account.lastName}</h3>
                <p>
                  Your IP Address: {ipAdd} has been logged on our server at{' '}
                  {time} to monitor money laundering. <br /> For transfers to
                  external bank account, proceed by clicking our checkbox to
                  your right.
                </p>
                <p>
                  Please, Ensure to fill the field correctly
                </p>
              </div>
              <form>
                <input
                  type='checkbox'
                  name='check'
                  id='check'
                  onChange={handleCheck}
                  style={{marginRight:'1rem'}}
                />
                <label htmlFor='check'>To external account</label>
              </form>
            </div>
          </div>
          <div className='bottomTansfer'>
            <div className='left'>
              <div className='right'>
                {transfer === false ? (
                  <form className='transfer-form' onSubmit={handleSubmit}>
                    <InputLabel
                      labelValue={'To Account'}
                      inputType={'text'}
                      placeHolder={'Account number'}
                      // inputValue={''}
                      inputName={''}
                      handleInputChange={handleOnChange}
                    />
                    <InputLabel
                      labelValue={'Amount'}
                      inputType={'text'}
                      placeHolder={'Enter Amount'}
                      // inputValue={''}
                      inputName={'amount'}
                      handleInputChange={handleOnChange}
                    />
                  </form>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <InputLabel
                      labelValue={"Select Sender's Account"}
                      inputType={'text'}
                      placeHolder={''}
                      inputValue={AccNum}
                      inputName={'AccNum'}
                      handleInputChange={handleOnChange}
                    />
                    <InputLabel
                      labelValue={"Receiver's Bank"}
                      inputType={'text'}
                      placeHolder={''}
                      // inputValue={''}
                      inputName={''}
                      handleInputChange={handleOnChange}
                    />
                    <InputLabel
                      labelValue={"Receiver's Name"}
                      inputType={'text'}
                      placeHolder={''}
                      // inputValue={''}
                      inputName={''}
                      handleInputChange={handleOnChange}
                    />
                    <InputLabel
                      labelValue={"Receiver's Bank Account Number"}
                      inputType={'text'}
                      placeHolder={''}
                      inputValue={transactionAcc}
                      inputName={'transactionAcc'}
                      handleInputChange={handleOnChange}
                    />
                    <InputLabel
                      labelValue={'Transfer Purpose'}
                      inputType={'text'}
                      placeHolder={''}
                      // inputValue={}
                      inputName={''}
                      handleInputChange={handleOnChange}
                    />
                    {/* <div className='formInput'>
                      <label htmlFor='status'>Select Recipient Account</label>
                      <select
                        id='status'
                        name='status'
                        onChange={''}
                        value={''}
                      >
                        <option>Please Select</option>
                        <option value='checking'>checking</option>
                        <option value='saving'>saving</option>
                      </select>
                    </div> */}
                    <InputLabel
                      labelValue={'SWIFT/ABA ROUTING NUMBER'}
                      inputType={'text'}
                      inputValue={swift}
                      inputName={'swift'}
                      handleInputChange={handleOnChange}
                    />
                    <InputLabel
                      labelValue={'Your Email Address'}
                      inputType={'email'}
                      // inputValue={}
                      inputName={''}
                      handleInputChange={handleOnChange}
                    />
                    <InputLabel
                      labelValue={'Enter Recipient Country'}
                      handleInputChange={handleOnChange}
                    />

                    <div className='formInput'>
                      <label htmlFor='amount'>Amount</label>
                      <div className='addon'>
                        <p>USD</p>
                        <input
                          type='text'
                          name='amount'
                          id='amount'
                          value={amount}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
              <div className='amount-card'>
                <div className='cash'>
                  <h2 style={{color: 'green'}}>USD {account  ? account?.balance : '0' }</h2>
                  <p>Account Balance</p>
                </div>
                <button type='submit' onClick={handleSubmit}>
                  Process Transfer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
