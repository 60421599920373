import React, { useState } from "react";
import InputLabel from "../../components/inputLabel/inputLabel";
import PasswordInput from "../../components/passwordInput/passwordInput";
import Button from "../../components/button/button";
import "./userLoginPage.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import UserAuthContext from "../../context/userAuth/userAuthContext";
import Logo from "../../images/logo.png";
import LoadingModal from "../../components/loginModal/LoginModal";
const UserLoginPage = () => {
  const navigate = useNavigate();
  const userAuthContext = useContext(UserAuthContext);

  const { loginUser, isVerified, err, clearErr } = userAuthContext;
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  useEffect(() => {
    if (isVerified) {
      navigate("/dashboard");
    }
    if (err === "Invalid Credentials") {
      clearErr();
    }
    //eslint-disable-next-line
  }, [err, isVerified]);

  const [contact, setContact] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false); // Loading state

  const { email, password } = contact;

  const handleOnChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true on form submit
     setShowLoadingModal(true);
    await loginUser({
      email,
      password,
    });
    setShowLoadingModal(false);
    setLoading(false); // Set loading back to false after login attempt
  };


  return (
    <div className="User_Login">
      <div className="logoDiv">
        <p style={{ fontSize: "2rem", color: "white", fontWeight: "bold", display: "flex", alignItems: "flex-end" }}>
          <img src={Logo} style={{width:'4rem'}} alt="" />
          Lumina Trust
        </p>
      </div>
      <div className="User_LoginContainer">
        <h1 className="pageTitle_login">SIGN IN</h1>
        <form className="loginForm" onSubmit={handleOnSubmit}>
          <InputLabel
            labelValue={"Email Address"}
            inputType={"text"}
            inputName={"email"}
            placeHolder={"Enter Email Address"}
            inputValue={email}
            handleInputChange={handleOnChange}
            required
          />
          <PasswordInput
            pwdValue={password}
            pwdName={"password"}
            placeHolder={"Enter password"}
            handleInputChange={handleOnChange}
            required
          />
          <div className="rememberSection">
            <label className="termsChecked">
              <input
                type="checkbox"
                required
                // onChange={(e) => setIsEnabled(e.target.checked)}
              />
              <span className="checkmark"></span>
            </label>
            <p className="rememberChecked">Remember me</p>
          </div>
          <div className="loginButton">
            <Button
              buttonText={loading ? "Logging in..." : "LOGIN"} // Change button text based on loading state
              buttonColor={"var(--tertiary-color)"}
              buttonWidth={"100%"}
              handleClick={handleOnSubmit}
              disabled={loading} // Disable button while loading
            />
          </div>
        </form>
      </div>
        { showLoadingModal && <LoadingModal />} 
    </div>
  );
};

export default UserLoginPage;
