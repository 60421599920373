import "./adminSidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import AdminContext from "../../context/admin/adminContext";

const Sidebar = () => {
  const adminContext = useContext(AdminContext);
  const authContext = useContext(AuthContext);
  const { logoutAdmin } = authContext;
  const navigate = useNavigate()

  const handleLogOut = async() => {
    await logoutAdmin();
    navigate('/admin/login')
    adminContext.clearAccounts();
  };

  return (
    <div className="adminSidebar">
        <div
          className="top"
        >
          <Link to='/admin/dashboard' style={{ textDecoration: 'none' }}>
          <span className='logo'>Lumina Trust</span>
        </Link>
        </div>
      <div className="center">
        <ul>
          <p className="title">ADMIN</p>
          <Link to="/admin/dashboard">
            <li>
              <DashboardIcon className="icon" />
              <span className="dashboardList">Dashboard</span>
            </li>
          </Link>

          <p className="title">LISTS</p>
          <Link to="/admin/dashboard/new" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>New Account</span>
            </li>
          </Link>
          <Link to="/admin/dashboard" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Manage Customers</span>
            </li>
          </Link>
          <Link to="/admin/dashboard/logs">
            <li>
              <CreditCardIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>

          <p className="title">TRANSACTIONS</p>
          <Link to="/admin/dashboard/credit">
            <li>
              <NotificationsNoneIcon className="icon" />
              <span>Process Transfer</span>
            </li>
          </Link>

          <p className="title">USER</p>
          <li onClick={handleLogOut}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
